import React from "react";
import { SEO } from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class AboutUsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          ogUrl={"https://www.stellabeautynb.com/about-us/"}
        />
        <Grid
          className={"custom-page-about-us custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"ldqztufuhgzpgwcq"}
            style={{ padding: 16 }}
            columns={1}
          >
            <Grid.Column className={"vvajikjinenfslix"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <React.Fragment
                  key={"JSON__dc7a8cd7-5c98-4473-b3f1-16149b926b29"}
                >
                  <div className={"custom-content-container"}>
                    <div className={"hero-overlay"} />
                    <div className={"hero-img"} />
                    <div className={"hero-content"}>
                      <h2>What Sets Stella Apart</h2>
                      <Grid
                        stackable={true}
                        verticalAlign={"middle"}
                        textAlign={"center"}
                        centered={true}
                        column={"4"}
                      >
                        <Grid.Row stretched={true}>
                          <Grid.Column
                            mobile={16}
                            computer={4}
                            textAlign={"center"}
                          >
                            <h4>Gratuity Free</h4>
                            <p>
                              <span>
                                No tip anxiety here! We value your support, but
                                we proudly operate as a gratuity free salon.
                                You’ll always know the exact cost of your
                                appointment at the time of booking. The best way
                                to show your appreciation?
                              </span>
                              <a
                                href={"https://g.co/kgs/qtVQgxf"}
                                target={"_blank"}
                              >
                                Leave a review
                              </a>
                              <span>&nbsp;and spread the word!</span>
                            </p>
                          </Grid.Column>
                          <Grid.Column
                            mobile={16}
                            computer={4}
                            textAlign={"center"}
                          >
                            <h4>Gender Free Service Menu</h4>
                            <p>
                              We offer an inclusive, gender-free service menu
                              designed to welcome everyone. Our goal is to
                              create a safe and comfortable space. Just select
                              your preferred stylist and service duration, and
                              we’ll ensure you have a comfortable and enjoyable
                              experience!
                            </p>
                          </Grid.Column>
                          <Grid.Column
                            mobile={16}
                            computer={4}
                            textAlign={"center"}
                          >
                            <h4>Hourly Pricing</h4>
                            <p>
                              Hourly pricing allows you to pay a fixed rate for
                              the time you spend with your stylist, rather than
                              individual prices for each service. This approach
                              ensures transparency, with no hidden fees or
                              unexpected charges. You can relax and enjoy your
                              visit, assured that you know the total cost
                              beforehand.
                            </p>
                          </Grid.Column>
                          <Grid.Column
                            mobile={16}
                            computer={4}
                            textAlign={"center"}
                          >
                            <h4>No Double Booking</h4>
                            <p>
                              Included in our hourly price is our full
                              attention. We strive to give you the individual
                              care you deserve. By focusing on one client at a
                              time, we ensure that you receive our complete
                              focus and enjoy a relaxed and stress free
                              environment.
                            </p>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </div>
                  </div>
                </React.Fragment>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 27390 }) {
      title
      seoTitle
      description
    }
  }
`;
